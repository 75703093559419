import { FC } from "react";

import Logo from "images/logo.webp";

import {
  HeaderContainer,
  HeaderContainerLinks,
  HeaderLogo,
  HeaderLink,
} from "./styles";

type HeaderProps = {
  motivationEl?: HTMLDivElement;
  categoriesEl?: HTMLDivElement;
  contactEl?: HTMLDivElement;
};

export const Header: FC<HeaderProps> = ({
  motivationEl,
  categoriesEl,
  contactEl,
}) => {
  const scrollOnPage = (element: any) => {
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <HeaderContainer>
      <a href="/">
        <HeaderLogo src={Logo} alt="logo de bae application pour les couples" />
      </a>
      <HeaderContainerLinks>
        <HeaderLink onClick={() => scrollOnPage(motivationEl)}>
          Application
        </HeaderLink>
        <HeaderLink onClick={() => scrollOnPage(categoriesEl)}>
          Concept
        </HeaderLink>
        <HeaderLink onClick={() => scrollOnPage(contactEl)}>Contact</HeaderLink>
      </HeaderContainerLinks>
    </HeaderContainer>
  );
};
