export const SendMessage = async ({
  email,
  message,
}: {
  email: string;
  message: string;
}) => {
  const rawResponse = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/send-message`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, message }),
    }
  );

  const content = rawResponse.json();

  return content;
};
