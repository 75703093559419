import { FC, useState, RefObject } from "react";

import Couple from "images/couple-contact.webp";

import Logo from "images/logo.webp";

import {
  ContactContainer,
  ContactTitle,
  ContactWrapper,
  ContactForm,
  ContactSubtitleTitle,
  ContactInput,
  ContactArea,
  ContactFormButton,
  ContactImageCouple,
  ContactLogo,
} from "./styles";
import { SendMessage } from "./service";

type ContactProps = {
  contactRef: RefObject<HTMLDivElement>;
};

export const Contact: FC<ContactProps> = ({ contactRef }) => {
  const [formData, setFormData] = useState({ email: "", message: "" });

  const handleEmailChange = (e: any) => {
    setFormData({ ...formData, email: e.target.value });
  };

  const handleMessageChange = (e: any) => {
    setFormData({ ...formData, message: e.target.value });
  };

  const sendFormData = () => {
    SendMessage({ email: formData.email, message: formData.message });
  };

  return (
    <ContactContainer id="contact" ref={contactRef}>
      <ContactTitle>Nos contacts</ContactTitle>
      <ContactWrapper>
        <ContactForm>
          <ContactSubtitleTitle>
            Vous avez une question, des remarques ou des idées d'améliorations ?
            C'est ici !
          </ContactSubtitleTitle>
          <h2>Votre email</h2>
          <ContactInput type="email" onChange={handleEmailChange} />
          <h2>Votre message</h2>
          <ContactArea onChange={handleMessageChange} />
          <ContactFormButton onClick={sendFormData}>Envoyer</ContactFormButton>
        </ContactForm>
        <ContactImageCouple
          src={Couple}
          alt="humains avec des coeurs dans les brass"
        />
      </ContactWrapper>
      <ContactLogo src={Logo} alt="logo de bae application pour les couples" />
    </ContactContainer>
  );
};
