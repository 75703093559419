import styled from "styled-components";

import { Device, Colors } from "utils/variables";
import Wave from "images/wave.webp";

export const KeepInformedContainer = styled.div`
  background: url(${Wave});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 20px;

  @media (max-width: ${Device.portraitTablet}) {
    background-color: ${Colors.pink};
    padding: 50px 20px;
  }
`;

export const KeepInformedCoupleImage = styled.img`
  max-width: 500px;
  width: 100%;
  margin-left: 50px;

  @media (max-width: ${Device.landscapeTablet}) {
    max-width: 400px;
    margin-bottom: 20px;
    margin-left: 0;
  }

  @media (max-width: ${Device.mobile}) {
    width: 300px;
  }
`;

export const KeepInformedContent = styled.div`
  display: flex;
  position: relative;

  @media (max-width: ${Device.portraitTablet}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const KeepInformedTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  @media (max-width: ${Device.mobile}) {
    padding: 20px 15px;
    text-align: center;
    align-items: center;
  }
`;

export const KeepInformedTitle = styled.h2`
  color: #fff;
  font-size: 46px;
  font-weight: 800;
  margin-bottom: 20px;

  @media (max-width: ${Device.landscapeTablet}) {
    font-size: 34px;
  }

  @media (max-width: ${Device.mobile}) {
    font-size: 26px;
  }
`;

export const KeepInformedText = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  color: #fff;
  max-width: 600px;
  line-height: 1.5;

  @media (max-width: ${Device.mobile}) {
    font-size: 16px;
    text-align: center;
  }
`;

export const KeepInformedLabel = styled.label`
  font-size: 18px;
  font-weight: 700;
  display: inline-block;

  color: #fff;

  @media (max-width: ${Device.mobile}) {
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
  }
`;

export const KeepInformedCta = styled.a`
  width: 250px;
  margin-bottom: 10px;
  padding: 20px 50px;

  border-radius: 5px;
  text-decoration: none;

  background-color: #fff;
  color: ${Colors.pink};

  font-weight: 800;
  font-family: "Baloo 2";
  font-size: 18px;

  @media (max-width: ${Device.mobile}) {
    margin-bottom: 50px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: 70%;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: ${Device.mobile}) {
    width: 100%;
  }
`;

export const KeepInformedInput = styled.input`
  width: 100%;
  border: 2px solid white;
  border-radius: 5px;
  font-size: 18px;
  padding: 18px 80px 18px 18px;
  outline: none;

  @media (max-width: ${Device.mobile}) {
    padding: 16px 40px 16px 12px;
    font-size: 16px;
  }
`;

export const KeepInformedSendEmailButton = styled.div`
  position: absolute;
  display: flex;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  @media (max-width: ${Device.mobile}) {
    right: 15px;
  }
`;

export const KeepInformedSendEmailButtonIcon = styled.img`
  width: 20px;

  @media (max-width: ${Device.mobile}) {
    width: 15px;
  }
`;
