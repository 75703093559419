import { BrowserRouter as Router, Route } from "react-router-dom";

import Homepage from "pages/homepage";
import TermsOfUse from "pages/termsOfUse";
import PrivacyPolicy from "pages/privacyPolicy";

const App = () => (
  <Router>
    <Route exact path="/" component={Homepage} />
    <Route path="/terms-of-use" component={TermsOfUse} />
    <Route path="/privacy-policy" component={PrivacyPolicy} />
  </Router>
);

export default App;
