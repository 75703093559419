import { FC, useRef, useState, useEffect } from "react";
import styled from "styled-components";

import Arrow from "images/up-arrow.webp";
import { Layout } from "components/layout";
import {
  KeepInformed,
  Motivations,
  Categories,
  Reviews,
  Contact,
} from "./sections";

export const ContactArrow = styled.img`
  position: fixed;
  right: 40px;
  top: 88vh;
  width: 50px;
  cursor: pointer;
  box-shadow: 0px 0px 13px 0px rgba(34, 34, 34, 0.3);
  border-radius: 50%;
`;

const Homepage: FC = () => {
  const [motivationEl, setMotivationEl] = useState<HTMLDivElement>();
  const [categoriesEl, setCategoriesEl] = useState<HTMLDivElement>();
  const [contactEl, setContactEl] = useState<HTMLDivElement>();
  const motivationRef = useRef<HTMLDivElement>(null);
  const categoriesRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const scrollOnTop = () => {
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    if (motivationRef.current && categoriesRef.current && contactRef.current) {
      setMotivationEl(motivationRef.current);
      setCategoriesEl(categoriesRef.current);
      setContactEl(contactRef.current);
    }
  }, []);

  return (
    <Layout
      motivationEl={motivationEl}
      categoriesEl={categoriesEl}
      contactEl={contactEl}
    >
      <KeepInformed />
      <Motivations motivationRef={motivationRef} />
      <Categories categoriesRef={categoriesRef} />
      <Reviews />
      <Contact contactRef={contactRef} />
      <ContactArrow src={Arrow} alt="arrow" onClick={scrollOnTop} />
    </Layout>
  );
};

export default Homepage;
