import styled from "styled-components";

import { Colors, Device } from "utils/variables";

export const MotivationsContainer = styled.div`
  text-align: center;
  padding: 100px 50px;
  @media (max-width: ${Device.mobile}) {
    padding: 50px 10px;
  }
`;

export const MotivationsTitle = styled.h2`
  color: ${Colors.pink};
  font-weight: 800;
  font-size: 32px;
  margin-bottom: 10px;
`;

export const MotivationsSubtitle = styled.h2`
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 50px;
`;

export const MotivationSection = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${Device.portraitTablet}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const MotivationWrapper = styled.div`
  margin: 50px;
  @media (max-width: ${Device.mobile}) {
    margin: 25px;
  }
`;

export const MotivationImage = styled.img`
  width: 80px;
  margin-bottom: 20px;
  @media (max-width: ${Device.mobile}) {
    width: 50px;
  }
`;

export const MotivationTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  @media (max-width: ${Device.mobile}) {
    font-size: 22px;
  }
`;

export const MotivationText = styled.p`
  font-size: 18px;
  line-height: 24px;
`;
