import Puzzle from "images/puzzle.webp";
import Hands from "images/hands.webp";
import Smartphone from "images/smartphone.webp";

export const MotivationData = [
  {
    image: Smartphone,
    alt: "Icône de téléphone",
    title: "L’application n°1 en jeu de couple",
    text: "Un jeu de couple regroupant des questions où l’on ouvre son cœur, et où l’on partage ses émotions.",
  },
  {
    image: Puzzle,
    alt: "Icône puzzle",
    title: "Revenir à l’essentiel à deux",
    text: "Développer une relation épanouie et créer des moments d’échange uniques pour son couple.",
  },
  {
    image: Hands,
    alt: "Icône mains",
    title: "Le jeu où le temps s'arrête",
    text: "Prendre le temps de discuter avec votre moitié, vous écouter et passer des moments de qualité.",
  },
];
