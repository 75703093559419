import { FC } from "react";

import PlayStore from "images/google-play-logo.webp";
import AppStore from "images/apple-logo.webp";
import Instagram from "images/logo-instagram.svg";
import Twitter from "images/logo-twitter.svg";
import Tiktok from "images/logo-tiktok.svg";

import {
  FooterContainer,
  LinksContainer,
  LogoWrap,
  Logo,
  FooterStores,
  FooterStore,
} from "./styles";

export const Footer: FC = () => (
  <FooterContainer>
    <p>© 2021 Bae App, All Rights Reserved</p>
    <LinksContainer>
      <a href="/privacy-policy">Politique de confidentialité</a>
      <a href="/terms-of-use">Conditions d'utilisation</a>
    </LinksContainer>
    <LogoWrap>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/baeapp.fr/"
      >
        <Logo src={Instagram} alt="logo du reseau social instagram" />
      </a>
      <a target="_blank" rel="noreferrer" href="https://twitter.com/bae_mobile">
        <Logo src={Twitter} alt="logo du reseau social twitter" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.tiktok.com/@bae.mobile"
      >
        <Logo src={Tiktok} alt="logo du reseau social tiktok" />
      </a>
    </LogoWrap>
    <FooterStores>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://apps.apple.com/fr/app/bae-jeu-de-couple/id1574150149"
      >
        <FooterStore src={AppStore} alt="logo app store pour bae" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://play.google.com/store/apps/details?id=fr.bae.app&gl=FR"
      >
        <FooterStore src={PlayStore} alt="logo play store pour bae" />
      </a>
    </FooterStores>
  </FooterContainer>
);
