import styled from "styled-components";

import { Colors, Device } from "utils/variables";

export const FooterContainer = styled.div`
  background-color: ${Colors.pink};
  color: ${Colors.white};
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 20px;
  & > p {
    margin: 10px;
  }
`;

export const FooterStores = styled.div`
  display: flex;

  @media (max-width: ${Device.mobile}) {
    justify-content: center;
  }
`;

export const FooterStore = styled.img`
  width: 150px;
  padding-right: 5px;

  @media (max-width: ${Device.mobile}) {
    width: 125px;
    padding: 2px;
  }
`;

export const LinksContainer = styled.div`
  margin-top: 10px;
  & a {
    color: #fff;
    margin: 10px;
    text-decoration: underline;
  }
`;

export const LogoWrap = styled.div`
  display: flex;
`;

export const Logo = styled.img`
  width: 35px;
  margin: 10px;
`;
