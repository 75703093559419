import styled from "styled-components";
import Slider from "react-slick";

import { Colors, Device } from "utils/variables";

export const CategoriesContainer = styled.div`
  background-color: ${Colors.pink};
  padding: 100px 0;
`;

export const CategoriesTitle = styled.div`
  color: ${Colors.white};
  text-align: center;
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 50px;
`;

export const CategoriesCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 15px;
  background-color: white;
  width: 320px !important;
  padding: 20px 10px;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const CategoriesCardSliderItem = styled.div`
  width: 100%;
  display: flex !important;
  justify-content: center;

  @media (max-width: ${Device.mobile}) {
    padding: 0 10px;
  }
`;

export const CategoriesCardIcon = styled.img`
  margin-bottom: 20px;
  width: 50px;
  @media (max-width: ${Device.portraitTablet}) {
    width: 40px;
    margin-bottom: 10px;
  }
`;

export const CategoriesCardTitle = styled.h3`
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const CategoriesCardText = styled.p`
  font-size: 18px;
  text-align: center;
  line-height: 25px;
  @media (max-width: ${Device.portraitTablet}) {
    font-size: 16px;
  }
`;

export const CategoriesCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  @media (max-width: ${Device.portraitTablet}) {
    display: none;
  }
`;

export const SliderCustom = styled(Slider)`
  display: none;
  & > .slick-dots {
    bottom: -40px;
  }

  @media (max-width: ${Device.portraitTablet}) {
    display: block;
  }
`;
