export const Colors = {
  pink: "#ec4554",
  pinkLight: "#ea707a",
  white: "#ffff",
  black: "#000000",
};

export const Device = {
  mobile: "480px",
  portraitTablet: "768px",
  landscapeTablet: "1024px",
  desktop: "1200px",
};

export const boxShadow = {
  type_1: "0px 1px 15px rgba(0, 0, 0, 0.15)",
  type_2: "0px 1px 30px rgba(0, 0, 0, 0.3)",
};
