import { FC, RefObject } from "react";

import { MotivationData } from "./data";
import {
  MotivationsContainer,
  MotivationsTitle,
  MotivationsSubtitle,
  MotivationSection,
  MotivationWrapper,
  MotivationImage,
  MotivationTitle,
  MotivationText,
} from "./styles";

type MotivationsProps = {
  motivationRef: RefObject<HTMLDivElement>;
};

export const Motivations: FC<MotivationsProps> = ({ motivationRef }) => (
  <MotivationsContainer id="application" ref={motivationRef}>
    <MotivationsTitle>Bae !</MotivationsTitle>
    <MotivationsSubtitle>Comment ça marche ?</MotivationsSubtitle>
    <MotivationSection>
      {MotivationData.map((data, id) => (
        <MotivationWrapper key={id}>
          <MotivationImage src={data.image} alt={data.alt} />
          <MotivationTitle>{data.title}</MotivationTitle>
          <MotivationText>{data.text}</MotivationText>
        </MotivationWrapper>
      ))}
    </MotivationSection>
  </MotivationsContainer>
);
