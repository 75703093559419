import { FC } from "react";

import {
  ReviewsContainer,
  ReviewsTitle,
  ReviewsWrap,
  Review,
  ReviewsText,
  ReviewsName,
} from "./styles";

export const Reviews: FC = () => (
  <ReviewsContainer>
    <ReviewsTitle>Nos témoignages</ReviewsTitle>
    <ReviewsWrap>
      <Review>
        <ReviewsText>
          « C’est très sympatique cette manière de passer des moments avec mon
          copain. Des soirées avec pleins de histoire et d’humour. Merci !! »
        </ReviewsText>
        <ReviewsName> Charline - 23 ans</ReviewsName>
      </Review>
      <Review>
        <ReviewsText>
          « J’étais à la recherche d’une application pour couple qui ne soit pas
          trop ennuyeuse et je n’ai pas été déçu ! Mention spéciale pour les
          questions coquine ! »
        </ReviewsText>
        <ReviewsName> Christophe - 35 ans</ReviewsName>
      </Review>
    </ReviewsWrap>
  </ReviewsContainer>
);
