import styled from "styled-components";

import { Device } from "utils/variables";

export const ReviewsContainer = styled.div`
  text-align: center;
  margin: 100px;

  @media (max-width: ${Device.mobile}) {
    margin: 50px 0;
  }
`;

export const ReviewsTitle = styled.h2`
  font-weight: 800;
  font-size: 36px;
  margin-bottom: 20px;
`;

export const ReviewsWrap = styled.div`
  display: flex;

  @media (max-width: ${Device.portraitTablet}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Review = styled.div`
  margin: 50px;
  @media (max-width: ${Device.mobile}) {
    margin: 25px;
  }
`;

export const ReviewsText = styled.p`
  font-size: 22px;
  line-height: 30px;

  @media (max-width: ${Device.mobile}) {
    font-size: 18px;
  }
`;

export const ReviewsName = styled.p`
  font-size: 22px;
  margin: 10px;
  font-weight: 600;
  color: #ec4554;

  @media (max-width: ${Device.mobile}) {
    font-size: 18px;
  }
`;
