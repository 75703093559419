import ReactDOM from "react-dom";

import { GlobalStyle } from "utils/index";
import App from "./App";

ReactDOM.render(
  <>
    <GlobalStyle />
    <App />
  </>,
  document.getElementById("root")
);
