import { FC } from "react";

import { Layout } from "components/layout";
import {
  TermOfUseTitle,
  TermOfUseContainer,
  TermOfUseParagraph,
} from "./styles";

const TermsOfUse: FC = () => (
  <Layout>
    <TermOfUseContainer>
      <TermOfUseTitle>Terms of use</TermOfUseTitle>
      <TermOfUseParagraph>
        You can subscribe for this app to get access to premium features and
        unlimited content.
        <br />
      </TermOfUseParagraph>

      <TermOfUseParagraph>
        Subscription is 1-week. ​ All prices are subject to change without
        notification. We occasionally run promotion prices as incentives or
        limited time opportunities for qualifying purchases made during the
        promotional time period. Due to the time sensitive and promotional
        nature of these events, we are unable to offer price protection or
        retroactive discounts or refunds for previous purchases in the event of
        a price reduction or promotional offering. Payment will be charged to
        iTunes Account at confirmation of purchase. ​
      </TermOfUseParagraph>

      <TermOfUseParagraph>
        Subscription automatically renews unless auto-renew is turned off at
        least 24-hours before the end of the current period. Account will be
        charged for renewal within 24-hours prior to the end of the current
        period at the cost of the chosen package. Subscriptions may be managed
        by the user and auto-renewal may be turned off by going to the user's
        iTunes Account Settings after purchase. No cancellation of the current
        subscription is allowed during active subscription period. ​
      </TermOfUseParagraph>
    </TermOfUseContainer>
  </Layout>
);

export default TermsOfUse;
