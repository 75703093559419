import { FC, RefObject } from "react";

import { CustomArrowPrev, CustomArrowNext } from "components/slider-controls";
import {
  CategoriesContainer,
  CategoriesTitle,
  CategoriesCard,
  CategoriesCardIcon,
  CategoriesCardTitle,
  CategoriesCardText,
  CategoriesCardContainer,
  SliderCustom,
  CategoriesCardSliderItem,
} from "./styles";
import { dataCategories } from "./data";

type CategoriesProps = {
  categoriesRef: RefObject<HTMLDivElement>;
};

export const Categories: FC<CategoriesProps> = ({ categoriesRef }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: true,
    prevArrow: <CustomArrowPrev className="" onClick={() => {}} />,
    nextArrow: <CustomArrowNext className="" onClick={() => {}} />,
  };

  return (
    <CategoriesContainer id="concept" ref={categoriesRef}>
      <CategoriesTitle>Les catégories dans Bae</CategoriesTitle>
      <SliderCustom {...settings}>
        {dataCategories.map((data, i) => (
          <CategoriesCardSliderItem key={i}>
            <CategoriesCard>
              <CategoriesCardIcon src={data.icon} />
              <CategoriesCardTitle>{data.title}</CategoriesCardTitle>
              <CategoriesCardText>{data.text}</CategoriesCardText>
            </CategoriesCard>
          </CategoriesCardSliderItem>
        ))}
      </SliderCustom>

      <CategoriesCardContainer>
        {dataCategories.map((data, i) => (
          <CategoriesCard key={i}>
            <CategoriesCardIcon src={data.icon} />
            <CategoriesCardTitle>{data.title}</CategoriesCardTitle>
            <CategoriesCardText>{data.text}</CategoriesCardText>
          </CategoriesCard>
        ))}
      </CategoriesCardContainer>
    </CategoriesContainer>
  );
};
