import styled from "styled-components";

import { Device, Colors } from "utils/variables";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 60px;

  @media (max-width: ${Device.mobile}) {
    padding: 10px 20px;
  }
`;

export const HeaderLogo = styled.img`
  margin: 10px;
  width: 80px;

  @media (max-width: ${Device.mobile}) {
    width: 50px;
  }
`;

export const HeaderContainerLinks = styled.div`
  width: 35%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${Device.portraitTablet}) {
    width: 60%;
  }
  @media (max-width: ${Device.mobile}) {
    width: 70%;
  }
`;

export const HeaderLink = styled.a`
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  color: ${Colors.black};

  &:hover {
    color: ${Colors.pink};
  }
  @media (max-width: ${Device.mobile}) {
    font-size: 14px;
  }
`;
