import styled from "styled-components";

import { Colors, Device } from "utils/variables";

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContactTitle = styled.h2`
  text-align: center;
  font-weight: 800;
  font-size: 36px;
  margin-bottom: 50px;
`;

export const ContactWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;

  @media (max-width: ${Device.landscapeTablet}) {
    flex-direction: column;
    align-items: center;
    padding: 0 5%;
  }
`;

export const ContactForm = styled.div`
  width: 400px;
  & > h2 {
    font-size: 18px;
  }

  @media (max-width: ${Device.mobile}) {
    max-width: 300px;
  }
`;

export const ContactSubtitleTitle = styled.h3`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: left;
`;

export const ContactInput = styled.input`
  width: 100%;
  margin: 10px 0;
  border: 2px solid grey;
  border-radius: 4px;
  padding: 15px;
  outline: none;
`;

export const ContactArea = styled.textarea`
  width: 100%;
  margin: 10px 0;
  border: 2px solid grey;
  border-radius: 4px;
  padding: 15px;
  outline: none;
`;

export const ContactFormButton = styled.button`
  width: 100%;
  font-family: "Baloo 2";
  font-size: 18px;
  color: ${Colors.white};
  background-color: ${Colors.pink};
  border: 2px solid ${Colors.pink};
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  &:hover {
    opacity: 90%;
    cursor: pointer;
  }
`;

export const ContactImageCouple = styled.img`
  width: 500px;

  @media (max-width: ${Device.mobile}) {
    width: 300px;
  }
`;

export const ContactLogo = styled.img`
  width: 80px;
  margin: 50px;
`;
