import { FC } from "react";

import { Layout } from "components/layout";
import {
  PrivacyPolicyTitle,
  PrivacyPolicyContainer,
  PrivacyPolicySubtitle,
  PrivacyPolicyParagraph,
} from "./styles";

const PrivacyPolicy: FC = () => (
  <Layout>
    <PrivacyPolicyContainer>
      <PrivacyPolicyTitle>PRIVACY POLICY OF BAE</PrivacyPolicyTitle>

      <PrivacyPolicySubtitle>
        1. AIM OF THE PRIVACY POLICY
      </PrivacyPolicySubtitle>
      <PrivacyPolicyParagraph>
        The Privacy Policy represents an ongoing concern for BAE (hereinafter
        referred to as BAE) in ensuring the protection of the private lives of
        users of the services described hereinafter in view of current
        regulations and in particular, Law 78-17 of January 6, 1978 relating to
        information technology, files and freedom and and with the EU's General
        Data Protection Regulation (GDPR) (EU) 2016/679. The current Privacy
        Policy is an integral part of the Terms and Conditions of Use governing
        the games and software applications provided by BAE, in particular via
        third-party social network platforms (hereinafter referred to as
        “Platforms”), including all content, all services and all features
        accessed via these games and software applications (hereinafter referred
        to collectively as “Services”). In particular, the current Privacy
        Policy aims to inform you of the general policy implemented by BAE in
        terms of protecting personal data collected and processed as a result of
        using the Services governed by the aforementioned Terms and Conditions
        of Use. By accessing and/or using the Services, you explicitly accept
        the terms of this Privacy Policy and, as a consequence, agree to the
        gathering, retaining, using and divulging of your personal data, as
        described herein. You are hereby advised that the Privacy Policy can be
        modified by BAE at any time. With this in mind, by continuing to use
        and/or access the Services after the last-modified date of any changes,
        you will be deemed to have accepted these changes as modified. You are
        therefore invited to regularly consult the Privacy Policy to ensure you
        are up-to-date with any potential changes.
      </PrivacyPolicyParagraph>

      <PrivacyPolicySubtitle>2. DATA COLLECTION</PrivacyPolicySubtitle>
      <PrivacyPolicyParagraph>
        2.1 Data collected by BAE via the Platforms The data collected and
        processed are willingly supplied by you to BAE through the Platforms by
        agreeing to access and use the Services from these Platforms. At a
        minimum, such data will include your name, profile photo, sex, your
        network(s), username, the user ID numbers of your friends and/or people
        belonging to your network(s) and other information supplied by you to
        all users of the Platforms. If applicable, BAE is also likely to
        collect, still via the Platforms, any additional data you may agree to
        supply to it (e.g., your email address). As all personal data are
        supplied directly to BAE by the Platforms, it is deemed that you have
        read and agreed to the Platforms’ terms and conditions of use and their
        privacy policy. BAE shall not be held liable for the way in which
        Platforms act in terms of collecting, storing and processing your
        personal data. In addition, you are responsible for the privacy settings
        of your accounts on the Platforms in such a way that BAE cannot be held
        liable in this regard. 2.2 Data collected by BAE directly Through
        provision of its Services, BAE may also collect data from you directly.
        As such, by using certain features within the Services which require
        online payment, you agree that your bank details, email address and/or
        your telephone number may be collected in compliance with current
        regulations in force. Within the context of using its Services and/or
        related official spaces (dedicated official spaces on the Platforms,
        support website, etc.), BAE may also invite you to participate in
        promotional events (contests, free sweepstakes, etc.). Participation in
        these promotional events implies your full and complete agreement to the
        collection, storage and processing of any personal data you supply. If
        applicable, and within the context of using the Services, the number of
        points you collect may be retained in order to determine your rank/level
        with the particular purpose of defining player rankings. By using the
        Services, you agree, in particular, that your score, your rank/level,
        your ID and your photo be listed in the overall player ranking, which
        all users of the Services will be able to view. Furthermore, when you
        contact BAE directly via its support service, the latter may collect and
        store your contact data (in particular your name, mailing address and
        email address) and, where applicable, your user ID number for the
        Platforms from where you access and use the Services, as well as some
        information relating to your use of the Services that you will provide
        to BAE. Finally, BAE may need to collect aggregate data in order to be
        able to provide an optimized level of Services. This may include data
        such as your IP address, browser type, browser language, number of
        clicks, pages viewed and also, more generally, browsing and connection
        data.
      </PrivacyPolicyParagraph>
      <PrivacyPolicySubtitle>
        3. PURPOSES OF COLLECTING AND PROCESSING YOUR DATA
      </PrivacyPolicySubtitle>

      <PrivacyPolicyParagraph>
        BAE collects, stores and processes your personal data with the aim of
        enabling you to access and use its Services effectively. Data collected
        from the Platforms and/or directly from you enable in particular the
        better administration and improvement of the Services, communication
        between users of the Services, the supply of technical support and
        efficient responses to user requests, proper application of the Terms
        and Conditions of Use, which govern BAE Services, communication about
        new and current Services along with promotional offers, the analysis of
        user traffic trends and the collection of statistical data or even the
        targeting of advertising content that may appear on our Services. When
        you receive promotional communications on behalf of BAE, you will have
        the opportunity to put and end to them by following the unsubscribe
        instructions contained in the communication itself. ​
      </PrivacyPolicyParagraph>
      <PrivacyPolicySubtitle>4. RECIPIENTS OF YOUR DATA</PrivacyPolicySubtitle>
      <PrivacyPolicyParagraph>
        Your data will not be provided to third parties, except in the following
        cases: when BAE must share your data with commercial partners,
        suppliers, service providers and in particular, technical service
        providers, resellers and buyers, with the aim of providing Services,
        resolving functionality problems of Services, communicating with users
        of the Services or with a view to providing advertising and promotional
        information related to Services; in particular, BAE supplies your data
        to third parties that provide services on their behalf, including
        payment processing, data analysis, email campaign processing, the
        hosting of Services and the processing of certain marketing activities
        for its Services; BAE may communicate your data in applying a law,
        regulation or in accordance with a decision from an appropriate
        regulating or legal authority or, if it proves necessary, with the aim
        of safeguarding its rights and interests; BAE will be authorized to
        transfer your data in compliance with article 10 of the present
        regulations in the event of a change of control of BAE or a cessation of
        all or part of its activities. when you have given prior agreement to
        the sharing and/or divulging of your data, including by features offered
        on the Platforms; We would like to draw your attention in particular to
        the fact that by using certain BAE Services via the Platforms, other
        users of these Services will be able to view your user profile for these
        Platforms. This profile may enable other users to obtain information
        associated with this profile made public by you. BAE cannot be held
        liable in this regard. The settings for information associated with your
        profile are your responsibility. BAE does not share your personal data
        with third-party advertisers for marketing purposes unless you have
        provided prior consent for it. Nevertheless, it should be noted that
        advertisers may obtain personal data which relates to you from your user
        profile on the Platforms or from other public sources. In the same way,
        BAE shall not be held responsible in this regard. On the other hand, BAE
        may use and share certain non-personal technical or aggregate data
        relating to the browsing of Services users with certain third parties.
        Please refer to article 7 for a few examples of browsing data. BAE may
        allow advertisers to collect this type of technical or aggregate data
        using certain technology such as cookies and to do so in order to be
        able to offer targeted advertising within the more general context of
        personalizing your user experience. The gathering, processing and
        divulging of such anonymous data shall not be subject to the
        restrictions cited in the current privacy policy.
      </PrivacyPolicyParagraph>

      <PrivacyPolicySubtitle> 5. SECURITY OF YOUR DATA</PrivacyPolicySubtitle>
      <PrivacyPolicyParagraph>
        BAE does its absolute best to protect your personal data and therefore
        to limit the risk of any loss, deterioration or incorrect use of it. As
        such, when you enter sensitive information such as a credit card number
        or password while using our Services, we encode said information using
        the “SSL” cryptographic protocol (for more information about SSL, go to
        http://en.wikipedia.org/wiki/Secure_Sockets_Layer). In spite of the
        security measures undertaken, BAE shall not guarantee the transporting
        and integrity of data transmitted to third parties through the Services
        (for example, when buying Physical and Virtual Products) and cannot be
        held liable in this regard. You accept in particular the limits and
        risks inherent to using the Internet and you promise as of now to take
        all preventive measures available to you to safeguard the privacy of
        your personal data (including any potential account passwords required
        to use the Services). ​
      </PrivacyPolicyParagraph>

      <PrivacyPolicySubtitle>
        6. SAFEGUARDING OF YOUR DATA
      </PrivacyPolicySubtitle>
      <PrivacyPolicyParagraph>
        Your personal data is stored with the host identified in the Legal
        Section and are held for the period of time necessary for carrying out
        the aforementioned purposes. Beyond this period of time, they will be
        kept exclusively for statistical purposes and will not be used in any
        other way whatsoever. ​
      </PrivacyPolicyParagraph>

      <PrivacyPolicySubtitle>7. COOKIES</PrivacyPolicySubtitle>

      <PrivacyPolicyParagraph>
        Within the context of facilitating your access and use of the Services,
        of personalizing this access and use and also of enabling us to manage
        the advertising which may be viewed from our Services, you are advised
        that BAE is likely to apply an automatic tracking procedure (cookie). A
        cookie does not enable you to be identified. On the other hand, it
        records information relating to your computer’s browsing of sites from
        where you have accessed the Services and other associated areas (pages
        you have viewed, the date and time of viewing, etc.) that we will be
        able to read during your subsequent visits. However, you can opt to turn
        off “Cookies” by configuring your browser according to the instructions
        indicated on the Wikihow website
        here:http://www.wikihow.com/Clear-Your-Browser’s-Cookies. Please note
        that configuring your browser in this way may change the quality of the
        Services. ​
      </PrivacyPolicyParagraph>

      <PrivacyPolicySubtitle>
        8. THIRD-PARTY PRIVACY POLICY
      </PrivacyPolicySubtitle>

      <PrivacyPolicyParagraph>
        The Services may contain links to third-party websites. Likewise,
        certain Services content (advertising related or otherwise) may be
        hosted with third parties. Finally, within the context of certain
        Services features requiring online payment, you may be asked to use
        secure payment tools provided by third-party companies. BAE cannot be
        held liable for practices relating to the collection, storage and
        processing of personal data by these third parties and/or the content of
        their websites. You warrant BAE against any complaint, action and
        procedure initiated in this regard. If you have any questions about the
        use of your personal data by these third parties, you should refer to
        their applicable privacy policies and contact the third-party companies
        directly.
      </PrivacyPolicyParagraph>

      <PrivacyPolicySubtitle>
        9. TRANSFER OF DATA INTERNATIONALLY
      </PrivacyPolicySubtitle>

      <PrivacyPolicyParagraph>
        For the purposes of the Services’ functioning, personal data collected
        by BAE are hosted in the USA. BAE and its host respect the SafeHarbor
        framework as cited by the Department of Trade concerning the collection,
        use and retention of online user data from the European Union. BAE takes
        all reasonable measures to ensure that your data are processed in
        complete security and in compliance with this Privacy Policy. By
        submitting your data to BAE and, more generally, by accessing and using
        the Services, you agree to the transfer, storage and/or processing of
        your data in the USA.
      </PrivacyPolicyParagraph>

      <PrivacyPolicySubtitle>10. CESSATION / TRANSFER</PrivacyPolicySubtitle>

      <PrivacyPolicyParagraph>
        BAE reserves the right to transfer your personal data in the event of a
        change of control or the cessation of all or part of its activities, in
        particular through acquisition or merger with another company. In such a
        case, BAE will use all means to warn users of any such transfer and if
        applicable, any change affecting the Privacy Policy.
      </PrivacyPolicyParagraph>

      <PrivacyPolicySubtitle>
        11. PRIVACY POLICY CONCERNING CHILDREN UNDER 16
      </PrivacyPolicySubtitle>
      <PrivacyPolicyParagraph>
        The Services offered by BAE are not intended for children under 16.
        Anyone under 16 should not use the Services. With this in mind, VYS
        Games does not willingly collect personal data from children under 16.
        All the same, if the collection of such data is brought to the attention
        of BAE, we will take all useful measures possible to delete the
        corresponding data.
      </PrivacyPolicyParagraph>

      <PrivacyPolicySubtitle>
        12. RIGHT OF ACCESS, MODIFICATION AND DELETION OF YOUR PERSONAL DATA
      </PrivacyPolicySubtitle>

      <PrivacyPolicyParagraph>
        In compliance with legal provisions, you have a general right to access,
        edit and delete your personal data which have been collected pursuant to
        your access to and/or use of the Services. Regarding data collected via
        the Platforms in particular, you can access and edit such data directly
        from the personal area available to you on these Platforms. If you have
        no personal area, you should refer to the Platforms’ access and editing
        policy.
      </PrivacyPolicyParagraph>
      <PrivacyPolicySubtitle> 13. ANY QUESTIONS?</PrivacyPolicySubtitle>
      <PrivacyPolicyParagraph>
        If you have any questions about BAE’ Services or Privacy Policy, feel
        free to contact us via email at baee@gmail.com
      </PrivacyPolicyParagraph>
    </PrivacyPolicyContainer>
  </Layout>
);

export default PrivacyPolicy;
