import React from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";
import { Colors, boxShadow } from "utils/variables";
import { switchProp } from "styled-tools";

type CustomArrowProps = {
  className: string;
  onClick: () => void;
};
export const CustomArrowNext = ({ className, onClick }: CustomArrowProps) => (
  <ButtonCustom
    style={{ display: className.includes("slick-disabled") ? "none" : "" }}
    direction="next"
    onClick={onClick}
  >
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M9.01763 8.17248L4.06091 12.1886C3.63975 12.5298 2.95941 12.5298 2.53825 12.1886C2.11709 11.8474 2.11709 11.2961 2.53825 10.9549L6.72825 7.55126L2.53825 4.15638C2.11709 3.81514 2.11709 3.26391 2.53825 2.92267C2.95941 2.58143 3.63975 2.58143 4.06091 2.92267L9.01763 6.93878C9.43879 7.27127 9.43879 7.83125 9.01763 8.17248Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          x="0.222412"
          y="0.666748"
          width="11.1111"
          height="13.7778"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </ButtonCustom>
);
export const CustomArrowPrev = ({ className, onClick }: CustomArrowProps) => (
  <ButtonCustom
    style={{ display: className.includes("slick-disabled") ? "none" : "" }}
    onClick={onClick}
    direction="prev"
  >
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M2.98237 6.82773L7.93909 2.81162C8.36025 2.47038 9.04059 2.47038 9.46175 2.81162C9.88291 3.15286 9.88291 3.70409 9.46175 4.04533L5.27175 7.44896L9.46175 10.8438C9.88291 11.1851 9.88291 11.7363 9.46175 12.0775C9.04059 12.4188 8.36025 12.4188 7.93909 12.0775L2.98237 8.06144C2.56121 7.72895 2.56121 7.16897 2.98237 6.82773Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          x="0.666504"
          y="0.555695"
          width="11.1111"
          height="13.7778"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </ButtonCustom>
);

const ButtonCustom = styled.button<{ direction: any }>`
  display: flex;
  align-items: center;
  background-color: ${Colors.pinkLight};
  box-shadow: ${boxShadow.type_1};
  border-radius: 50%;
  width: ${rem(32)};
  height: ${rem(32)};
  position: absolute;
  z-index: 1;
  padding: 0;
  justify-content: center;
  top: 40%;
  border: none;
  ${switchProp("direction", {
    next: css`
      right: 0;
      transform: translateX(-2px);
      svg {
        transform: translateX(1px);
      }
    `,
    prev: css`
      left: 0;
      transform: translateX(2px);
      svg {
        transform: translateX(0px);
      }
    `,
  })}
`;
