import { FC } from "react";
import styled from "styled-components";

import { Header } from "components/header";
import { Footer } from "components/footer";

type LayoutProps = {
  motivationEl?: HTMLDivElement;
  categoriesEl?: HTMLDivElement;
  contactEl?: HTMLDivElement;
};

const LayoutContainer = styled.div`
  position: relative;
`;

export const Layout: FC<LayoutProps> = ({
  children,
  motivationEl,
  categoriesEl,
  contactEl,
}) => (
  <LayoutContainer>
    <Header
      motivationEl={motivationEl}
      categoriesEl={categoriesEl}
      contactEl={contactEl}
    />
    {children}
    <Footer />
  </LayoutContainer>
);
