import styled from "styled-components";

export const PrivacyPolicyContainer = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  padding: 48px;
`;

export const PrivacyPolicyTitle = styled.h2`
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 70px;
`;

export const PrivacyPolicySubtitle = styled.h3`
  font-weight: bold;
  margin-bottom: 4px;
`;

export const PrivacyPolicyParagraph = styled.p`
  margin-bottom: 24px;
`;
