import { FC, useState } from "react";

import Couple from "images/couple.svg";

import SendIcon from "images/send.webp";
import { SendEmail } from "./service";
import {
  KeepInformedContainer,
  KeepInformedContent,
  KeepInformedTitle,
  KeepInformedText,
  KeepInformedCoupleImage,
  KeepInformedInput,
  KeepInformedTextContainer,
  InputContainer,
  KeepInformedSendEmailButton,
  KeepInformedSendEmailButtonIcon,
  KeepInformedLabel,
  KeepInformedCta,
} from "./styles";

export const KeepInformed: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const onChange = (event: any) => {
    setEmail(event.target.value);
  };

  const onSendEmail = async () => {
    const { message } = await SendEmail(email);
    setError(message);
  };

  return (
    <KeepInformedContainer>
      <KeepInformedContent>
        <KeepInformedCoupleImage
          src={Couple}
          alt="illustration de couple faisant un calin"
        />
        <KeepInformedTextContainer>
          <KeepInformedTitle>Prendre le temps de discuter !</KeepInformedTitle>
          <KeepInformedText>
            Le meilleur jeu pour couple ! Bae vous permet de savourer un moment
            de jeu a deux. Ce jeu de couple contient près de 800 questions et
            défis pour un maximum de fun !
          </KeepInformedText>
          <KeepInformedCta
            target="_blank"
            rel="noreferrer"
            href="http://urlz.fr/h2C3"
          >
            Télécharger Bae
          </KeepInformedCta>
          <KeepInformedLabel>
            Et pour être au courant des nouveautés de Bae, c'est par ici :
          </KeepInformedLabel>
          <InputContainer>
            <KeepInformedInput
              type="email"
              name="email"
              placeholder="exemple@exemple.com"
              onChange={onChange}
            />
            <KeepInformedSendEmailButton onClick={onSendEmail}>
              <KeepInformedSendEmailButtonIcon src={SendIcon} alt="envoyer" />
            </KeepInformedSendEmailButton>
          </InputContainer>
          {error && <p>{error}</p>}
        </KeepInformedTextContainer>
      </KeepInformedContent>
    </KeepInformedContainer>
  );
};
