import Hot from "images/hot-icon.webp";
import Like from "images/like-icon.webp";
import Which from "images/witch-icon.webp";
import Talk from "images/talk-icon.webp";
import Add from "images/add-icon.webp";

export const dataCategories = [
  {
    icon: Hot,
    title: "Sexy",
    text: "Echangez en profondeur sur vos envies intimes afin de développer une complicité torride !",
  },
  {
    icon: Like,
    title: "Tu prefères ?",
    text: "Decouvrer si votre partnaire connais vos habitudes, ce que vous aimer, detester ect... Vous connaissez vous par cœur ?",
  },
  {
    icon: Which,
    title: "Qui de nous deux",
    text: "Partagez vos meilleures anecdotes grâce à des questions originales. Vous risquez d’être étonné !",
  },
  {
    icon: Talk,
    title: "Débats",
    text: "Des questions qui suscitent une prise de recul sur vos envies, vos valeurs et vos objectifs de vie.",
  },
  {
    icon: Add,
    title: "Vos questions",
    text: "Créer vos propres questions de couples pour des soirées de plus en plus folles et infini.",
  },
];
